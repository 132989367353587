/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

/**
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component
 */
@import "all/custom/variables.scss";

// Import Bootstrap source files (Required file)
@import "all/bootstrap/bootstrap.scss";
@import "all/custom/custom.scss";

//This is for the icons (Required file)
@import "all/icons/font-awesome/css/fontawesome-all.css";
@import "all/icons/simple-line-icons/css/simple-line-icons.css";
@import "all/icons/weather-icons/css/weather-icons.css";
@import "all/icons/themify-icons/themify-icons.css";
@import "all/icons/flag-icon-css/flag-icon.min.css";
@import "all/icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "all/icons/crypto-icons/cryptocoins.css";

/* Import Google Fonts for Link Landing Page */

/* Abril Fatface */
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
/* Amatic SC */
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap');
/* Antonio */
@import url('https://fonts.googleapis.com/css2?family=Antonio&display=swap');
/* Bowlby One SC */
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');
/* Cinzel */
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
/* Courgette */
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
/* Figtree */
@import url('https://fonts.googleapis.com/css2?family=Figtree&display=swap');
/* Fjalla One */
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
/* Fredoka One */
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
/* Inter */
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
/* Josefin Slab */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab&display=swap');
/* Kanit */
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
/* Lato */
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
/* Lora */
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
/* Mansalva */
@import url('https://fonts.googleapis.com/css2?family=Mansalva&display=swap');
/* Merriweather */
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
/* Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
/* Mukta */
@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');
/* Noto Sans */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
/* Nunito Sans */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');
/* Oswald */
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
/* Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
/* Playfair Display */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
/* PT Sans */
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
/* Raleway */
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
/* Roboto */
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
/* Rubik */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
/* Source Sans Pro */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
/* Ubuntu */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
/* Work Sans */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

@import "./minorCSS";

.dropdown-menu a {
  width: 100%;
  display: block;
}

.dropdown-menu .dropdown-item:not(.disabled) {
  color: #000a60;
}

.rdl-control,
.rdl-filter {
  border: 1px solid #e9ecef !important;
  color: #525f7f !important;
}

.dayPickerOuter .DayPicker-Day--disabled {
  background-color: #eff1f1;
}

.dayPickerOuter .DayPicker-Day--successDays {
  background-color: #ace0a5;
  color: #040404 !important;
}
.dayPickerOuter .DayPicker-Day--weekends {
  background-color: #ccc;
  color: #040404 !important;
}

.dayPickerOuter .DayPicker-Day--warrningDays {
  background-color: #fff7d8;
  color: #040404 !important;
}

.dayPickerOuter .DayPicker-Day {
  border-radius: 0 !important;
}

.dayPickerOuter .DayPicker-Day--outside,
.dayPickerOuter .DayPicker-Day--weekends.DayPicker-Day--disabled,
.dayPickerOuter .DayPicker-Day--successDays.DayPicker-Day--disabled,
.dayPickerOuter .DayPicker-Day--warrningDays.DayPicker-Day--disabled {
  color: #dce0e0 !important;
  cursor: default;
  background-color: #eff1f1 !important;
}

.dayPickerOuter .DayPicker-Day--disabled.DayPicker-Day--today {
  color: #d0021b !important;
}

.dayPickerOuter
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
.dayPickerOuter
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: orange !important;
}

.custom-control-label::after,
.custom-control-label::before {
  top: -16px !important;
  left: -24px !important;
  width: 20px !important;
  height: 20px !important;
}

.custom-radio {
  .custom-control {
    // &:first-of-type {
    margin-bottom: 0.5rem;
    // }
  }
  .custom-control-label::after,
  .custom-control-label::before {
    top: -1px !important;
    left: -24px !important;
    width: 20px !important;
    height: 20px !important;
  }
}

.form-check-label {
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
}

.nav-tabs .nav-link {
  cursor: pointer;
}

.artistUpload {
  height: 35px;
  position: absolute;
  top: 0px;
  left: 15px;
  bottom: 0;
  z-index: 1;
  background: #000a60;
  transition: 300ms all;
  max-width: calc(100% - 30px);
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.125) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.125) 50%,
    rgba(255, 255, 255, 0.125) 75%,
    transparent 75%,
    transparent
  );
  background-size: 35px 35px;
}

.trackUploadContainer {
  position: relative;
}
.trackUploadIconLabel {
  margin-top: 0px;
  font-size: 10px;
  text-align: center;
}
.trackUpload {
  height: 36px;
  position: absolute;
  top: -36px;
  z-index: 1;
  background: #000a60;
  transition: 300ms all;
  max-width: 100%;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.125) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.125) 50%,
    rgba(255, 255, 255, 0.125) 75%,
    transparent 75%,
    transparent
  );
  background-size: 35px 35px;
}

.trackUploadProgresDiv {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -18px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  padding: 2px 10px;
}

.trackUploadContainerDA {
  position: relative;
}
.trackUploadDA {
  height: 36px;
  position: absolute;
  top: -36px;
  z-index: 1;
  background: #000a60;
  transition: 300ms all;
  max-width: 100%;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.125) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.125) 50%,
    rgba(255, 255, 255, 0.125) 75%,
    transparent 75%,
    transparent
  );
  background-size: 35px 35px;
}

.trackUploadProgresDivDA {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -18px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  padding: 2px 10px;
}

.artworkUploadContainer{
  position: relative;
}
.artworkUpload {
  height: 36px;
  position: absolute;
  top: -36px;
  z-index: 1;
  background: #000a60;
  transition: 300ms all;
  max-width: 100%;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.125) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.125) 50%,
    rgba(255, 255, 255, 0.125) 75%,
    transparent 75%,
    transparent
  );
  background-size: 35px 35px;
}

.artworkUploadProgresDiv {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -19px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  padding: 2px 10px;
}

.trackUpdateProgress {
  height: 35px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0;
  z-index: 1;
  background: #5e72e4;
  transition: 300ms all;
  max-width: 100%;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.125) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.125) 50%,
    rgba(255, 255, 255, 0.125) 75%,
    transparent 75%,
    transparent
  );
  background-size: 35px 35px;
}


.uploadProgresDiv {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  padding: 2px 10px;
}


.hiddenInput {
  display: none;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
}

@media (max-width: 1399px) {
  .react-bootstrap-table {
    width: 100%;
    overflow-x: scroll;
    table {
      width: 1400px;
    }
  }
}

.custom-control-label {
  margin-bottom: 0;

  &.is-invalid:after{
    background-color: #dc3545 !important;
    opacity: 0.4;
  }
}

.custom-file.is-invalid{
  .custom-file-label{
    height: auto !important;
    border: unset !important;
  }
}

.ViewLayout-wrap{
  max-width: 1100px;
  margin: 0 auto;
}

.landing-wrapper{
  background-image: linear-gradient(43deg, rgb(245, 161, 0) 0%, rgb(214, 43, 78) 40%, rgb(177, 97, 255) 60%, rgb(51, 127, 222) 90%, rgb(39, 199, 126) 100%);
}

.social-icons-list{
  display: flex;

  .social-icon{
    i{
      font-size: 40px;
      cursor: pointer;

      &.fa-facebook{
        color: #3b5998;
      }
      &.fa-instagram{
        color: #c32aa3;
      }
      &.fa-soundcloud{
        color: #ff5500;
      }
    }
  }
}

.premade-color-circle-container {
  position: relative;
  margin-right: 100px;
  display: flex;
}

.premade-color-circle {
  width: 60px;
  height: 60px;
  border: 2px solid grey;
  border-radius: 50%;
}

.premade-color-circle:first-child {
  position: absolute;
  top: 0;
  left: 0;
}

.premade-color-circle:last-child {
  position: absolute;
  top: 0px;
  left: 30px;
}

.premade-color-name {
  font-size: 12px;
  position: relative;
  margin-top: 70px;
  margin-left: 5px;
  text-align: center;
  white-space: normal;
  width: 80px;
}

.premade-color-container:hover{
  cursor: pointer;
}

.color-selection-nav-item {
  background-color: #000a60 !important;
  border-radius: 10px;
  margin-right: 3px;
}

.color-selection-nav-item .nav-link {
  color: #ffffff !important;
  border-radius: 0px;
  background-color: transparent !important;
}

.color-selection-nav-item-active {
  background-color: #ccc !important;
}

.gradientCircleBtn:hover {
  color: #000a60;
  border: 2px solid #000a60;
}
