.subscription-upgrade {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}
  
.pricing-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    align-items: stretch;
}

.subscription-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 320px;
    padding: 40px;
    background: #fff;
    min-height: 100%;
    z-index: 1;
}

.subscription-card-ribbon-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 120px;
    overflow: hidden;
}

.subscription-card-ribbon {
    background: #000a60;
    color: #fff;
    position: absolute;
    display: block;
    top: 18px;
    right: -30px;
    width: 128px;
    padding: 9px 20px 8px;
    margin: 0;
    transform: rotate(45deg);
    height: 32px;
    z-index: 2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

.subscription-card h3 {
    color: #000a60;
    margin-bottom: 15px;
}

.tier-price-container {
    min-height: 60px;
    max-height: 60px;
}
  
.subscription-card .price {
    font-size: 24px;
    color: #333;
    margin: 15px 0;
}
  
.subscription-card ul {
    list-style: none;
    padding: 0;
    text-align: left;
    margin-bottom: 25px;
}

.subscription-card ul li {
    padding: 5px 0;
}

.subscription-card > ul {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.subscription-card button {
    background-color: #000a60;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}
  
.subscription-card button:not([disabled]):hover {
    background-color: var(--hover-bg-color) !important;
}