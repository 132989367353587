.artistWrap {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 0 20px 0;
  position: relative;
}

.artistType {
  /* width: 200px;
  margin: 0 20px 0 0; */
}

.artistSelect {
  width: 200px;
  margin: 0 20px 0 0;
}

.artistRemove {
  cursor: pointer;
}

.pseudoSelectCalendar {
  background-color: #fff !important;
  cursor: pointer;
  /* background-clip: padding-box;
  border: 1px solid #e9ecef;
  border-radius: 2px;
   */
}

.pseudoSelectCalendar p {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  margin: 0;
  line-height: 1.5;
  color: #525f7f;
}

.fv-help-block {
  color: #f62d51;
}