.acr-note-icon {
    opacity: 0.6;
}

.acr-note-avl-icon:hover {
    opacity: 1.0;
    cursor: pointer;
}

.acr-note-avl-icon {
    opacity: 0.8;
}

.popover.show.bs-popover-auto #qc-popover + .arrow::after {
    border-color: #fdd5dc transparent #fdd5dc transparent;
}

.releaseLicenseUploadContainer {
    position: relative;
}

.releaseLicenseUpload {
    height: 36px;
    position: absolute;
    top: -36px;
    z-index: 1;
    background: #000a60;
    transition: 300ms all;
    max-width: 100%;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.125) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.125) 50%,
      rgba(255, 255, 255, 0.125) 75%,
      transparent 75%,
      transparent
    );
    background-size: 35px 35px;
}

.releaseLicenseUploadProgressDiv {
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -19px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    padding: 2px 10px;
}