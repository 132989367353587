.loginLogo {
  background: #000a60;
  width: 100px;
  margin: 0 0 30px 0;
  border-radius: 50%;
}

.releaseFileRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.flex_start {
    justify-content: flex-start;
  }
  p {
    margin: 0;
    &.releaseRemoveFile{
      padding: 0 0 0 20px;
      cursor: pointer;
    }
  }
  .releaseFileRowName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tracksTable {
  .selection-cell,
  .selection-cell-header {
    display: none;
  }
}

tr.activeRow {
  background: rgba(27, 144, 218, 0.1);
}


.btn-status {
  padding: 8px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 4px;
  max-width: 100px;
  width: 100%;
}

.btn-status-big {
  padding: 8px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 4px;
  max-width: 140px;
  width: 100%;
}


.fontWeight700 {
  font-weight: 700;
  margin: 0;
}

.dashboard-select-outer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 20px 0 20px;
}
.dashboard-select-wrap {
  max-width: 200px;
  width: 100%;
}

.dashboard-header {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}

.dashboard-p {
  font-size: 14px;
}

.dashboard-icon {
  font-size: 25px;
  line-height: 1;
}
.dashbord-icon-outer {
  padding: 0 !important;
}

.nav-tabs {
  border: none;
}
.nav-tabs .nav-link {
  border: none;
  position: relative;
  z-index: 20;
  top: 3px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}


.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border:none;
}

.dashboard-3-items {
  padding: 2px 40px 0 40px;
}

.btn {
  border-radius: 4px !important;
}

.loaderInner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255, 0.3);
  z-index: 2;
}

.loaderPreview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.releaseSpinner {
  position: absolute;
  top: 200px;
  left: calc(50% - 12px);
}


.select-search {
  width: 200px;
  margin: 0 20px 0 0;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 5px);
  right: 16px;
  width: 8px;
  height: 8px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 2px 8px;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  outline: none;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
  align-items: center;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
  z-index: 5;
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  padding: 0;
}


/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option {
  display: block;
  min-height: 36px;
  width: 100%;
  padding: 8px 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Nunito Sans', sans-serif;
  color: hsl(0,0%,20%);
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #3f57bc;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(27, 144, 218, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #3f57bc;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 2px solid hsl(0,0%,80%);
  border-bottom: 2px solid hsl(0,0%,80%);
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #3f57bc;
  border-width: 2px;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}


.break-me {
  padding: 0 20px;
  display: flex;
  align-items: center;
  line-height: 1;
}

.pagination_outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 30px 0;
}

.select-search-wrap .select-search {
  width: 100%;
}


.promoshare1 {
  padding: 0px 0px; font-family: 'Nunito Sans', sans-serif; line-height:20px; height:100%;  width: 100%; color: #514d6a;
  width: 100%;
}

.promoshare2 {
  max-width: 700px; padding:50px 0;  margin: 0px auto; font-size: 14px
}
.promoshare3 {
  vertical-align: top; padding-bottom:30px;
}
.promoshare4 {
  background:#000a60; padding:20px; color:#fff; text-align:center;
}
.promoshareP {
  margin: 0 0 4px 0;
}


.selectAllWrap {
  display: flex;
  width: 100%;
  align-items: flex-start;
  > div {
    width: 100%;
    padding: 0 10px 0 0;
  }
}

.artist-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #512DA8;
  font-size: 27px;
  font-family: Roboto;
  font-style: normal;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin: 0px 0px;
  margin-right: 10px;
}

.wishlist-icon {
  opacity: 0.9;
}

.wishlist-icon:hover {
  opacity: 1.0;
  cursor: pointer;
}

.qc-issues-alert {
  border: 5px solid #f62d51 !important;
}

.collapse-text {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
}

.collapse-icon {
  color: #3e5569;
}

.collapse-icon:hover {
  color: #b40bbe;
  cursor: pointer;
}
