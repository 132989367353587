.ml-lp-content {
    position: fixed;
	content: "";
	width: 300px;
	height: 550px;
	border-radius: 15px;
    padding-top: 2px;
    padding-bottom: 2px;

	.logocard {
		border: 0;
		border-radius: 6px;
        margin-top: 10px;
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 10px;
        width: 280px;
	}
	.logocard .card-header {
		background: rgb(180,11,190);
        background: -moz-linear-gradient(239deg, rgba(180,11,190,1) 0%, rgba(0,10,96,1) 80%);
        background: -webkit-linear-gradient(239deg, rgba(180,11,190,1) 0%, rgba(0,10,96,1) 80%);
        background: linear-gradient(239deg, rgba(180,11,190,1) 0%, rgba(0,10,96,1) 80%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b40bbe",endColorstr="#000a60",GradientType=1);
		text-align: center;
		color: #fff;
		position: relative;
		box-shadow: 0px 34px 85px rgba(50, 50, 71, 0.04),
			0px 24.7297px 57.292px rgba(50, 50, 71, 0.032375),
			0px 17.2125px 37.0547px rgba(50, 50, 71, 0.027),
			0px 11.2891px 22.9932px rgba(50, 50, 71, 0.023125),
			0px 6.8px 13.8125px rgba(50, 50, 71, 0.02),
			0px 3.58594px 8.21777px rgba(50, 50, 71, 0.016875),
			0px 1.4875px 4.91406px rgba(50, 50, 71, 0.013),
			0px 0.345313px 2.60645px rgba(50, 50, 71, 0.007625);
		border-radius: 6px;
		padding: 1.75rem 1.25rem;
	}
    .card-header .triangle {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -19px;
		width: 25px;
		height: 31px;
	}
	.logocard .card-header h2 {
		color: #fff;
		font-size: 20px;
		font-weight: 600;
	}
	.logocard .card-header p {
		margin-bottom: 10px;
		font-size: 16px;
		font-weight: 500;
	}
	.logocard .card-body {
		padding: 0;
	}

	.logolist > * {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.logolist li {
		border-bottom: 1px solid #cfe9f9;
		border-right: 1px solid #cfe9f9;
		text-align: center;
		display: flex;
		flex-direction: column;
		padding: 24px;
		justify-content: center;
		align-items: center;

		&:nth-last-of-type(-n + 1):not(:nth-child(odd)) {
			border-bottom-right-radius: 6px;
			border-bottom: 0;
		}
		&:nth-last-of-type(-n + 2):not(:nth-child(even)) {
			border-bottom-left-radius: 6px;
			border-bottom: 0;
		}
	}
	.logolist li .empty {
		font-size: 21px;
		color: #3e5569;
	}
	.logolist li .brandlogo {
		margin-bottom: 16px;
		height: 33px;
	}
	.logolist li .downloadbnt {
		// border: 1px solid #3e556957;
		// color: #3e5569;
		border-radius: 50px;
		padding: 4px 12px;
		// background: #fff;
	}
	.logolist li .downloadbnt svg path {
		transition: all 0.15s ease-in;
	}
	// .logolist li .downloadbnt:hover {
	// 	background-color: #2dce89;
	// 	color: #fff;
	// 	border-color: #2dce89;
	// }
	// .logolist li .downloadbnt:hover svg {
	// 	fill: #ffffff;
	// }
	// .logolist li .downloadbnt:hover svg path {
	// 	fill: #ffffff;
	// }

	.tracktable {
		width: 100%;
		margin-bottom: 0px;
	}
	.tracktable thead tr th {
		border: none;
		background: #000;
		color: #fff;
	}
	.tracktable tbody tr td {
		max-width: 100px;
		border: none;
		background: #000;
		color: #fff;
		padding: 6px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 1.1rem;
	}
	.music-list-item {
		cursor: pointer;
	}
	.music-list-item-active > td {
		// color: #b40bbe !important;
	}

	.tablescroll {
		max-height: 225px;
		overflow-y: auto;
        margin-left: 10px;
        margin-right: 10px;
        border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}
	.tablescroll::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		border-radius: 40px;
		background-color: #333;
	}

	.tablescroll::-webkit-scrollbar-thumb {
		background: #666;
		width: 6px;
		height: 6px;
		border-radius: 40px;
	}
	.logolist li {
		&:nth-child(even) {
			border-right: 0;
		}
	}

    #canvas-parent canvas {
		position: absolute;
		bottom: 0;
	}
	.ml-lp-background-image {
		z-index: 1;
		top: 0px;
		content: "";
		position: fixed;
		left: 0px;
		width: 100%;
		height: 100%;
		filter: blur(0px);
		background-color: #000;
	}
	.overlay {
		background-color: rgba(0, 0, 0, 0.5);
		transition: opacity 0.2s linear;
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 1;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}
	.playermain {
		position: relative;
        margin: 0;
        margin-left: 10px;
        margin-right: 10px;
	}
	.playermain .audiobg {
		width: 100%;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}
	.playermain .cleft {
		top: 50%;
		bottom: auto;
		-moz-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		position: absolute;
		max-width: 36px;
		left: 12px;
		cursor: pointer;
	}
	.playermain .cright {
		top: 50%;
		bottom: auto;
		-moz-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		position: absolute;
		right: 12px;
		max-width: 36px;
		cursor: pointer;
	}
	.playermain .description {
		color: #fff;
		background-color: #030607;
		position: absolute;
		top: 10px;
		padding: 10px;
		font-size: 13px;
		left: 80px;
	}
	.playermain .description span {
		display: block;
	}
	.playermain .time-container {
		height: 15px;
		background-color: #030607;
		color: #fff;
		font-size: 11px;
		padding-left: 5px;
		padding-right: 5px;
		line-height: 14px;
		position: absolute;
		right: 5px;
		top: 0px;
	}
	.playermain .playicon {
		position: absolute;
		left: 50%;
		top: 50%;
		bottom: auto;
		right: auto;
		-moz-transform: translateX(-50%) translateY(-50%);
		-o-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
		cursor: pointer;
		transition: all 0.21s ease-in-out;
	}
	.playermain .playicon.active {
		top: 10px;
		left: 10px;
		max-width: 60px;
		transform: none;
	}
}

.gradient-background {
	background: linear-gradient(239deg, rgba(180, 11, 190, 1) 0%, rgba(0, 10, 96, 1) 80%);
}